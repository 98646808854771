<template>
  <div>
    <div
      class="find-water-child"
      v-for="(item, index) in zhList"
      :key="index"
      @click="jumpFindCompany(item)"
    >
      <div>
        <span>{{ item.tenant_name }}</span>
      </div>
      <div>
        <van-icon
          name="arrow"
          color="rgb(225,225,225)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zhList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
    }
  },

  methods: {
    jumpFindCompany (data) {
      this.$router.push({
        path: '/FindUser',
        query: { tenant_name: data.tenant_name, tenant_id: data.tenant_id },
      })
    },
  },
}
</script>
 
<style lang = "less" scoped>
.find-water-child {
  margin-bottom: 10px;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  flex-wrap: nowrap;
  font-size: 16px;
  span {
    color: black !important;
  }
}
</style>