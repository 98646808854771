<template>
  <div>
    <div>
      <div
        class="contentFw top-padding"
        v-show="showList"
      >
        <top-home
          title="查询租户"
          :className="['top-white']"
        ></top-home>
        <!-- <div class="bindUsers-f">
          <div class="bindUsers">
            <div class="van-two">
              <van-row>
                <van-col
                  span="7"
                  offset="1"
                >
                  <div
                    class="van-two-chio"
                    :class="{ active: active == 1 }"
                    @click="active = 1"
                  >
                    <img
                      src="../../../assets/PersonalCenter/waterPic.png"
                      alt
                      width="20px"
                    />
                    <span>水费</span>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div> -->
        <van-search
          v-model="value"
          label="租户名称："
          left-icon=""
          placeholder="请输入租户名称"
          show-action
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
        <TenantList :zhList="zhList"></TenantList>
      </div>
    </div>
  </div>
</template>

<script>
import { getWxGetZh } from '@/reuqest/api.js'
import TenantList from './TenantList.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    TenantList,
  },
  data () {
    return {
      value: '',
      showList: true,
      active: 1,
      zhList: [],
    }
  },
  computed: {
    ...mapGetters(['appId']),
  },
  created () {
  },
  methods: {
    getZhData (name) {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getWxGetZh(name).then((res) => {
        console.log(res)
        let data = []
        if (res.data.length > 0) {
          data = res.data.map((item) => {
            return item
          })
        } else {
          this.$toast('没有查找到相关租户，请确认租户名称')
        }
        this.zhList = data
        loadingToast.clear()
      }).catch(() => {
        this.zhList = []
        loadingToast.clear()
      })

    },
    onSearch (val) {
      this.getZhData(this.value)
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentFw {
  background-color: rgb(245, 245, 245);
  min-height: 100vh;
  .title {
    flex: 1;
    span {
      color: rgb(153, 153, 153);
      font-size: 14px;
    }
  }
}
.active {
  border-color: rgb(72, 180, 254) !important;
  color: rgb(95, 189, 254);
}
.bindUsers-f {
  background-color: #fff;
  .bindUsers {
    margin: 10px;

    .van-one {
      border-bottom: 1px solid rgb(238, 238, 238);
      padding-bottom: 12px;
      .van-row {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .van-two {
      justify-content: center;
      padding: 10px;
      .van-two-chio {
        border: 1px solid #ccc;
        padding: 5px 0;
        border-radius: 20px;
        font-size: 14px;
      }

      img {
        vertical-align: middle;
        padding-right: 5px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
/deep/.text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  span {
    border: 1px solid rgb(245, 245, 245);
    margin: 10px;
    padding: 8px 30px;
  }
}
.text-lis {
  display: flex;
  justify-content: space-between;
}
</style>